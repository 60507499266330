<template>
    <v-container class="text-left pa-0">
        <div v-if="!loading">
            <v-card flat>
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{'account' | localize }} {{firstName}}  {{lastName}}</v-toolbar-title>
                    <span class="primary--text ml-auto">{{'log Out' | localize }}</span>
                    <v-btn color="primary" icon small @click="logOut()">
                        <v-icon>mdi-logout</v-icon>
                    </v-btn>
                </v-toolbar>
                        
                <v-tabs v-model="tab" 
                    dense
                    color="primary"
                    vertical>
                    <!---desktop / mobile-->
                    <v-tab class=" text-lowercase mr-auto" dense
                        v-for="(item, i) in items" :key="i">
                        <v-icon class="mr-auto"
                            color="primary">mdi-{{item.icon}}</v-icon>
                        <span class="hidden-sm-and-down pl-2">{{ item.text }}</span>
                    </v-tab>
                    <!---contact info-->
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense
                                            :label="'First name' | localize"
                                            color="primary"
                                            v-model="firstName"
                                            @change="dataChanged()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense 
                                            :label="'Last name' | localize"
                                            color="primary"
                                            v-model="lastName"
                                            @change="dataChanged()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense 
                                            :label="'Email' | localize"
                                            color="primary"
                                            v-model="email"
                                            @change="dataChanged()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense 
                                            :label="'Phone' | localize"
                                            color="primary"
                                            v-model="phone"
                                            @change="dataChanged()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-btn class="mr-auto my-4 text-lowercase"
                                            color="primary"
                                            :disabled="!isChanged"
                                            outlined
                                            @click="editUserData()">
                                            {{'save' | localize }}
                                        </v-btn>
                                    </v-col>
                                </v-row>            
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!---shipping address-->
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense
                                            :label="'Country' | localize"
                                            color="primary"
                                            v-model="country"
                                            @change="dataChanged()">
                                        </v-text-field>
                                    </v-col>
                                </v-row >
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense 
                                            :label="'City' | localize"
                                            color="primary"
                                            v-model="city"
                                            @change="dataChanged()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-text-field dense 
                                            :label="'Address' | localize"
                                            color="primary"
                                            v-model="address"
                                            @change="dataChanged()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6">
                                        <v-btn class="mr-auto my-4 text-lowercase"
                                            color="primary" outlined
                                            :disabled="!isChanged"
                                            @click="editUserData()">
                                            {{'save' | localize}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                            
                    </v-tab-item>
                    <!---orders-->
                    <v-tab-item>
                        <div v-if="userOrders.length">
                            <v-container class="mt-4 hidden-xs-only">
                                <v-expansion-panels focusable>
                                    <v-expansion-panel v-for="order in userOrders"
                                        :key="order.id">
                                        <v-expansion-panel-header>
                                            <span class="px-1">{{'order' | localize}}#</span>
                                            <span>{{'date' | localize }}: {{ dateLocale(order.orderDate) }}</span> 
                                            <span class="px-1">{{'suma' | localize }}: {{ totalSuma(order).toLocaleString('en-US') }} {{order.currency}}</span>
                                            <span>{{ 'delivery' | localize }}: {{ order.delivery }} MDL</span> 
                                            <span v-if="order.discount > 0" class="px-1">{{ 'discount' | localize }}: {{ order.discount }}</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row class="font-weight-bold caption">
                                                <v-col>{{'сode' | localize }}</v-col>
                                                <v-col>{{'title' | localize }}</v-col>
                                                <v-col>{{'quantity' | localize }}</v-col>
                                                <v-col>{{'price' | localize }}</v-col>
                                                <v-col>{{'suma' | localize}}</v-col>
                                            </v-row>
                                            <v-row v-for="prod in order.products"
                                                :key="prod.id"
                                                class="caption">
                                                <v-col>{{ prod.id }}</v-col>
                                                <v-col>{{ prod.name }}</v-col>
                                                <v-col>{{ prod.quantity }}</v-col>
                                                <v-col>{{ prod.price }} {{order.currency}}</v-col>
                                                <v-col>{{ (prod.quantity * prod.price).toLocaleString('en-US') }} {{order.currency}}</v-col>
                                            </v-row>
                                        </v-expansion-panel-content>   
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-container>
                            <v-container class="hidden-sm-and-up">
                                <div v-for="order in userOrders" :key="order.id">
                                    <v-row class="my-2 body-2">
                                        <strong>
                                            {{'order' | localize}}# {{'date' | localize }}: {{ dateLocale(order.orderDate) }}
                                            {{'suma' | localize }}: {{ totalSuma(order).toLocaleString('en-US') }} {{order.currency}}
                                        </strong>
                                    </v-row>
                                    <v-divider></v-divider>
                                </div>
                            </v-container>
                        </div>
                        <div v-else
                            class="text-center"
                        >{{'No order yet' | localize }}</div>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </div>
        <div v-else>
            <loader />
        </div>
    </v-container>
</template>
<script>
import Loader from '../layouts/Loader.vue';
import localizeFilter from '../filters/localize.filter';

export default {
    data() {
        return {
            //props
            city: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: 'Moldova',
            address: '',
            isChanged: false,
            tab: 0,
            rules: {
                required: value => !!value || localizeFilter('required field'),
                phone: value => {
                    const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
                    return pattern.test(value) || localizeFilter('invalid phone')
                },
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || localizeFilter('invalid e-mail')
                },
                avatar: value => {
                    return !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
                }
            }
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        loggedIn() {
            return this.$store.getters.loggedIn;
        },
        userData() {
            return this.$store.getters.userData;
        },
        userOrders() {
            return this.$store.getters.userOrders;
        },
        locale() {
            return this.$store.getters.locale;
        },
        items() {
            
            return [
                {text: localizeFilter('contact information'), icon: 'account'},
                {text: localizeFilter('shipping address'), icon: 'truck'},
                {text: localizeFilter('orders'), icon: 'cart-arrow-down'}
            ] 
           
        },
        photo() {
            return this.$store.getters.photoUrl;
        }
    },
    watch: {
        loggedIn() {
            if (!this.loggedIn) {
                this.$router.push('/');
            }
        }
    },
    methods: {
        dateLocale(date) {
            if (date) {
                return new Date(date).toLocaleDateString(this.locale); 
            }
            return 'no date'
        },
        totalSuma(order) {
            return order.products.reduce((summ, prod) => {
                return summ + parseInt(prod.price * prod.quantity)
            }, 0);
        },
        async editUserData() {
            //for user data
            const data = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                phone: this.phone,
                country: this.country,
                city: this.city,
                address: this.address
            }
            //console.log(data);
            await this.$store.dispatch('updateUserData', data);
            this.isChanged = false;
        },
        dataChanged() {
            this.isChanged = true;
        },
        async logOut() {
            try {
               await this.$store.dispatch('logout'); 
            }
            catch(error) {
                await this.$store.dispatch('setError', error.message);
            }
        },
        isEmpty(obj) {
            for (var x in obj) {
                return false;
            }
            return true;
        },
    },
    async mounted() {
        await this.$store.dispatch('fetchUserOrders');
        
        if (!this.isEmpty(this.userData)) {
            this.firstName = this.userData.firstName  || '';
            this.lastName = this.userData.lastName || '';
            this.email = this.userData.email || '';
            this.phone = this.userData.phone || '';
            this.country = this.userData.country || 'Moldova';
            this.city = this.userData.city || '';
            this.address = this.userData.address || '';
        }
    },
    components: { Loader }
}
</script>
